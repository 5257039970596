
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AuthMixin, StringsMixin, VuetifyMixin, BAIconsMixin, AthleteApiMixin } from '@/mixins';
import { BAEventModel, EventTicketModel, EventParticipantDetails } from '@/models/baEvent';
import { AthleteProfileModel, BAPurchaseModel } from '@/models';
import { baEventApi } from '@/api/BAEventsApi';
import { athleteApi } from '@/api/AthleteApi';
import { baPurchaseApi } from '@/api/BAPurchaseApi';
import { DataTableHeader } from 'vuetify';
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import { BAEventAdminDetail } from '@/../types/constants/admin.routes'

@Component({
	components: {
		Page,
        RecruitingProfileInfo
	}
})
export default class BAEventAdminRedeemPage extends Mixins(StringsMixin, BAIconsMixin, AuthMixin, VuetifyMixin, AthleteApiMixin){
    userId;
    eventId;
    ticketId;
    baEvent: BAEventModel = undefined;
    baTicket: EventTicketModel = undefined;
    baParticipant: EventParticipantDetails = undefined;
    baAttendee: AthleteProfileModel = undefined;
    baPurchase: BAPurchaseModel = undefined;
    loadedEvent: boolean = false;
    updatingEvent: boolean = false;

    created() {
        this.userId = this.$route.query.userId;
        this.eventId = this.$route.query.eventId;
        this.ticketId = this.$route.query.ticketId;
    }
    mounted() {
        this.userId = this.$route.query.userId;
        this.eventId = this.$route.query.eventId;
        this.ticketId = this.$route.query.ticketId;

        this.loadEvent();
    }

    get IsLoading(): boolean {
        return !this.loadedEvent || !this.IsAthleteApiReady;
    }
    get IsUpdating(): boolean {
        return this.updatingEvent || this.IsLoading;
    }
    async loadEvent() {
        this.loadedEvent = false;
        this.baEvent = await baEventApi.findById(this.eventId);
        if( this.IsNotEmpty(this.baEvent) ) {
            await this.loadParticipants();

            if( this.IsNotEmpty(this.ticketId) && !this.ticketId.includes('all')) this.baTicket = await this.baEvent.tickets.find(t => t.id === this.ticketId);
            if( this.IsNotEmpty(this.userId) && !this.ticketId.includes('none')) this.baParticipant = await this.participants.find(p => p.attendeeId === this.userId);
            if( this.IsNotEmpty(this.baParticipant) ) this.baAttendee = this.baParticipant.athlete;
            if( this.IsNotEmpty(this.baAttendee) ) this.baPurchase = await baPurchaseApi.findPurchase(this.userId, this.eventId);

            if( !this.baEvent.manualCheckin ) await this.onParticipantCheckIn();
        }
        this.loadedEvent = true;
    }
    get ViewAllTickets() {
        return this.ticketId.includes('all');
    }
    get IsParticipantCheckedIn() {
        if( this.IsEmpty(this.baParticipant) ) return;
        return this.baParticipant.redeemed;
    }

    searchParticipants = "";
    get ParticipantHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'Name'},
            {text: 'Ticket', value: 'Ticket', sortable: true},
            {text: 'Checked In', value: 'redeemed', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
        ]
    }
    participants: EventParticipantDetails[] = [];
	get Participants(): EventParticipantDetails[] {
		return this.participants;
	}
    async loadParticipants() {
        this.participants = [];
        for( const attendee of this.baEvent.participants ) {
            const attendeeDetails: EventParticipantDetails = new EventParticipantDetails().load(attendee);
            attendeeDetails.athlete = await athleteApi.findById(attendee.attendeeId);
            attendeeDetails.ticket = await this.baEvent.Ticket(attendee.ticketId);
            this.participants.push(attendeeDetails);
        }
    }

    async updateEventParticipant(participant: EventParticipantDetails) {
        let baParticipant = this.baEvent.participants.find(p => p.attendeeId === participant.attendeeId);
        baParticipant.redeemed = participant.redeemed;
        this.saveEventChanges();
    }
    async onParticipantCheckIn(participant: EventParticipantDetails = undefined) {
        if( !participant && this.IsEmpty(this.baParticipant) ) return;
        if( !participant ) participant = this.baParticipant;

        participant.redeemed = true;
        this.updateEventParticipant(participant);
    }
    async onParticipantUndoCheckIn(participant: EventParticipantDetails = undefined) {
        if( !participant && this.IsEmpty(this.baParticipant) ) return;
        if( !participant ) participant = this.baParticipant;

        if( !confirm(`Are you sure you want to undo check in for ${participant.athlete.FullName}?`) ) return;

        participant.redeemed = false;
        this.updateEventParticipant(participant);
    }

    showAthleteCheckin: boolean = false;
    athleteToCheckIn: EventParticipantDetails = undefined;
    athleteToCheckInPurchase: BAPurchaseModel = undefined;
    async onAthleteCheckIn(participant: EventParticipantDetails) {
        this.athleteToCheckIn = participant;
        this.athleteToCheckInPurchase = await baPurchaseApi.findPurchase(participant.attendeeId, this.eventId);
        this.showAthleteCheckin = true;
    }
    async onAthleteCheckInOK() {
        await this.onParticipantCheckIn(this.athleteToCheckIn);
        await this.onAthleteCheckInComplete();
    }
    async onAthleteCheckInCancel() {
        await this.onAthleteCheckInComplete();
    }
    async onAthleteCheckInComplete() {
        this.showAthleteCheckin = false;
        this.athleteToCheckIn = undefined;
        this.athleteToCheckInPurchase = undefined;
    }

    async onChangeManualCheckin() {
        if( this.IsEmpty(this.baEvent) ) return;
        this.saveEventChanges();
    }

    async saveEventChanges() {
        this.updatingEvent = true;
        await baEventApi.save(this.baEvent)
        this.updatingEvent = false;
    }

	gotoEvents() {
		this.$router.push('/baevent-admin');
	}
	onViewEvent(baEvent: BAEventModel) {
		this.$router.push({
			name: BAEventAdminDetail,
			params: { eventId: baEvent.id }			
		});
	}
}
